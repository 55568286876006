import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { Providers } from "./components/Providers";
import { MyApplications } from "./components/MyApplications";
import { Faqs } from "./components/Faqs";
import { Home } from "./components/Home";
import { LogoutRedirect } from "./components/LogoutRedirect";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
    {
        path: '/providers',
        requireAuth: false,
        element: <Providers />
    },
    {
        path: '/myapplications',
        requireAuth: false,
        element: <MyApplications />
    },
    {
        path: '/faqs',
        requireAuth: false,
        element: <Faqs />
    },
    {
        path: '/LogoutRedirect',
        requireAuth: false,
        element: <LogoutRedirect />
    },
  ...ApiAuthorzationRoutes
];

export default AppRoutes;
