import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header>
                <Navbar className="navbar navbar-expand-lg mb-3 navbar-bg navbar-container">
                    <div className="container">
                        <div className="row"> {/*style="flex-grow: 1"*/}
                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pt-4 pb-4">{/* @* large and xL device expand menu*@*/}
                                <NavbarBrand className="navbar-brand w-100" tag={Link} to="/">
                                    <img src="/teqsa-logo_1.png" className="img-fluid w-90" alt="TEQSA Logo" />
                                </NavbarBrand>
                            </div>
                            <div className="d-lg-flex align-items-lg-center d-xl-flex align-items-xl-center justify-content-xl-end col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">

                                <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                                <Collapse className="navbar-collapse collapse flex-lg-row-reverse flex-xl-row-reverse" isOpen={!this.state.collapsed} navbar>
                                    <ul className="navbar-nav flex-grow">
                                        {/*ALL Navigation menu Items are managed from LoginMenu Component in React*/}

                                        {/*<NavItem>*/}
                                        {/*    <NavLink tag={Link} className="text-white" to="/">Home</NavLink>*/}
                                        {/*</NavItem>*/}
                                        {/*<NavItem>*/}
                                        {/*    <NavLink tag={Link} className="text-white" to="/Providers">Providers</NavLink>*/}
                                        {/*</NavItem>*/}
                                        <LoginMenu>
                                        </LoginMenu>
                                    </ul>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </Navbar>
            </header>
        );
    }
}
