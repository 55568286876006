import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService'

export class LogoutRedirect extends Component {
    static displayName = LogoutRedirect.name;

    constructor(props) {
        super(props);
        this.state = { inactive: false, loading: true };
    }

    componentDidMount() {
        console.log('redirect inactive user to logout page');

        if ((window.location.href).indexOf('inactive') >= 0) {
            //set inactive user flag to show inactive logout message
            localStorage.setItem('inactiveUser', '1');
        }
        //Call logout redirect fucnation with interval to run js after html elements are loaded
        setInterval(this.triggerLogoutRedirect, 10);//Call with interval to run js after html elements are loaded
    }


    render() {
        return (
            <div>
                <h1>Logging out</h1>
                 logging out because of inactivity.
            </div>
        );
    }

    async triggerLogoutRedirect() { 
        document.getElementById('logout-link')?.click();
    };
}
export default LogoutRedirect;
