import React, { Component } from 'react';
import DOMPurify from 'dompurify';
import Providers from './Providers';

export class Home extends Component {
    static displayName = Home.name;
    constructor(props) {
        super(props);
        this.state = { homePageContent: '', loading: true };
    }
    componentDidMount() {
        this.getHomePageContent();
    }
    static renderPageContent(homePageContent) {
        return (
            <div>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePageContent) }} />
            </div>
        );
    }
    render() {
        let homePageContentToRender = this.state.loading
            ? <p><em>Loading...</em></p>
            : Home.renderPageContent(this.state.homePageContent);
        return (
            <div>
                <div className="row">
                <p></p>
                </div>
                <div className="row">
                    <div className="col-md-6 column">
                        {homePageContentToRender }
                    </div>
                    <div className="col-md-6 column">
                        <Providers></Providers>
                    </div>
                </div>
            </div>
        );
    }

    async getHomePageContent() {
        const response = await fetch('/api/pagecontent/GetHomePageContent');
        const data = await response.text();
        this.setState({ homePageContent: data, loading: false });
    }
}
