import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService'
import DOMPurify from 'dompurify';
import { Link } from "react-router-dom"

export class MyApplications extends Component {
    static displayName = MyApplications.name;

    constructor(props) {
        super(props);
        this.state = { myapplications: [], loading: true, query: '', greenIdStatus: false, greenIdLoading: true, errorMessage: "", pageContentloading:true, pageContent: '' };
    }

    componentDidMount() {
        this.getValidationStatus();
        this.populateMyApplicationsData();
        this.getPageContent();
    }
    static renderPageContent(pageContent) {
        return (
            <div>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(pageContent) }} />
            </div>
        );
    }
    async getPageContent() {
        const response = await fetch('/api/pagecontent/GetMyApplicationsPageContent');
        const data = await response.text();
        this.setState({ pageContent: data, pageContentloading: false });
    }

    handleSearch = (event) => {
        this.setState({ query: event.target.value })
        console.log(this.state.query);
    };

    static renderGreenId() {
        return (<div>
            <p>Your identity has not been verified.</p>
            <p>
                <a href="/Identity/VerifyIdentity/GreenId" className="btn btn-primary">Verify your Identity</a>
        </p>
        </div>);
    }

    static renderMyApplicationsTable(myapplications, query) {
        return (
            <div className="row">
                <div className="col-md-12">
                    <table className='table table-striped' aria-labelledby="tabelLabel">
                        <thead>
                            <tr>
                                <th>Application ID</th>
                                <th>Application Status</th>
                                <th>Provider Code</th>
                                <th>Provider Name</th>
                                <th>Trading Name(s)</th>
                                <th>Course Code</th>
                                <th>Course Title</th>
                                <th>Submitted On</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {myapplications
                                .filter(myapplication => {
                                    if (query === '') {
                                        return myapplication;
                                    } else if (Object.keys(myapplication)
                                        .filter(key => (myapplication[key] || "").toString().toLowerCase().includes(query.toString().toLowerCase())).length > 0) {
                                        return myapplication;
                                    }
                                    return myapplication;
                                })
                                .map(myapplication =>
                                    <tr key={myapplication.spListItemId}>
                                        <td>{myapplication.spListItemId}</td>
                                        <td>{myapplication.applicationStatus}</td>
                                        <td>{myapplication.providerCode}</td>
                                        <td>{myapplication.providerLegalEntityName}</td>
                                        <td>{myapplication.providerTradingNames}</td>
                                        <td>{myapplication.courseCode}</td>
                                        <td>{myapplication.courseTitle}</td>
                                        <td>{myapplication.applicationSubmittedOn}</td>
                                        <td>
                                            <a className="btn btn-primary" href={"/Identity/ViewApplication?ID=" + myapplication.spListItemId}>View</a>
                                        </td>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                    {(myapplications && myapplications.length === 0) ? "No submitted applications found." : ""}
                </div >
            </div>
        );
    }

    static renderMyApplicationsNewButton() {
        return (
            <div>                
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <a className="btn btn-primary" href='/Identity/newapplication'>Submit new application</a>
                </div>

                {/*<div className="form-outline">*/}
                {/*    <input type="text" id="searchQuery" className="form-control" placeholder="Search" aria-label="Search"*/}
                {/*        onChange={this.handleSearch} value={this.state.query}/>*/}
                {/*</div>*/}
                
            </div>
        );
    }

    render() {
        let contents = this.state.loading && this.state.greenIdLoading
            ? <p><em>Loading...</em></p>
           
            : (this.state.greenIdStatus ? MyApplications.renderMyApplicationsTable(this.state.myapplications, this.state.query) : MyApplications.renderGreenId());
        let newButton = this.state.greenIdStatus ? MyApplications.renderMyApplicationsNewButton() : ""


        let pageContentToRender = this.state.pageContentloading && this.state.greenIdLoading
            ? <p><em>Loading...</em></p>
            : MyApplications.renderPageContent(this.state.pageContent);
        return (
            <div>
                {this.state.greenIdStatus ?
                    pageContentToRender
                    :""
                }
                {this.state.errorMessage ?
                    <div className="alert alert-danger" role="alert">
                        {this.state.errorMessage}
                    </div> :""}
                {newButton}
                {contents}
            </div>)
    }

    async getValidationStatus() {
        const token = await authService.getAccessToken();
        fetch('api/applications/getvalidationstatus', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) { //401 (Unauthorized)
                        console.error("401 (Unauthorized) - Redirecting to login page");
                        //Redirect to login page
                        window.location.href = "/authentication/login?returnUrl=" + window.location.href;
                    }
                    else {
                        console.error(response.status);
                        //Show error message to user
                        this.setState({ errorMessage: "Something went wrong." });
                        throw new Error("Fetch response is not successful.");
                    }
                }
                // If response is ok (200), return response json Data
                else {
                    return response.json();// If response is ok (200), return response json Data
                }
            })
            .then(data => {
                //Use response data
                this.setState({ greenIdStatus: data, greenIdLoading: false });
            })
            .catch(error => {
                console.error("Something went wrong.", error);
                //Show error message to user
                this.setState({ errorMessage: "Something went wrong." });
            });
    }

    async populateMyApplicationsData() {
        const token = await authService.getAccessToken();
        await fetch('api/applications', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) { //401 (Unauthorized)
                        console.error("401 (Unauthorized) - Redirecting to login page");
                        //Redirect to login page
                        window.location.href = "/authentication/login?returnUrl=" + window.location.href;
                    }
                    else {
                        console.error(response.status);
                        //Show error message to user
                        this.setState({ errorMessage: "Something went wrong." });
                        throw new Error("Fetch response is not successful.");
                    }
                }
                // If response is ok (200), return response json Data
                else {
                    return response.json();// If response is ok (200), return response json Data
                }
            })
            .then(data => {
                //Use response data
                this.setState({ myapplications: data, loading: false });
            })
            .catch(error => {
                console.error("Something went wrong.", error);
                //Show error message to user
                this.setState({ errorMessage: "Something went wrong." });
            });
    }
}
export default MyApplications;
