export const ApplicationName = 'TEQSA.SRMS.Portal';

export const QueryParameterNames = {
  ReturnUrl: 'returnUrl',
  Message: 'message'
};

export const LogoutActions = {
  LogoutCallback: 'logout-callback',
  Logout: 'logout',
    LoggedOut: 'logged-out'
};

export const LoginActions = {
  Login: 'login',
  LoginCallback: 'login-callback',
  LoginFailed: 'login-failed',
  Profile: 'profile',
  Register: 'register',
  MyAppplications: 'myapplications',
  GreenId: 'greenid'
};

const prefix = '/authentication';

export const ApplicationPaths = {
  DefaultLoginRedirectPath: '/',
  ApiAuthorizationClientConfigurationUrl: `_configuration/${ApplicationName}`,
  ApiAuthorizationPrefix: prefix,
  Login: `${prefix}/${LoginActions.Login}`,
  LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
  LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
  Register: `${prefix}/${LoginActions.Register}`,
  Profile: `${prefix}/${LoginActions.Profile}`,
  MyAppplications: `${prefix}/${LoginActions.MyAppplications}`,
  LogOut: `${prefix}/${LogoutActions.Logout}`,
  GreenId: `${prefix}/${LoginActions.GreenId}`,
  LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
  LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
  IdentityRegisterPath: 'Identity/Account/Register',
  IdentityManagePath: 'Identity/Account/Manage',
  IdentityGreenId: 'Identity/VerifyIdentity/GreenId',
  IdentityGreenIdComplete: 'Identity/VerifyIdentity/Complete',
  IdentityGreenIdPending: 'Identity/VerifyIdentity/Pending',
  IdentityGreenIdError: 'Identity/VerifyIdentity/Error',
  IdentityGreenIdCancelled: 'Identity/VerifyIdentity/Cancelled'
};
