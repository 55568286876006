export function checkInactiveUser() {
    //Check for Inactive user session and logout user, same js is loaded in .Net Layout and React LoginMenu component
    console.log('loading logout inactive user js');
    var sessExpiryMins = localStorage.getItem('sessExpiryMins'); // from Config - InactiveUserSessionExpiryMins
    console.log('session timeout value is ' + sessExpiryMins);
    var sessPollInterval =   60000; //check every one min
    var sessIntervalID;
    var sessLastActivity;
    var isAuthenticatedQ = function () {
        var isUserLoggedIn = document.getElementById('isUserLoggedIn')?.innerHTML;
        if (isUserLoggedIn == 'true') {
            console.log('user logged in');
            return true;
        }
        else {
            console.log('user not logged in');
            return false;
        }
    };
    var sessionCheck = function () {
        if (isAuthenticatedQ()) {
            console.log('user session activity check started');
            sessLastActivity = new Date();
            if (localStorage) {
                localStorage.setItem('LastActivity', sessLastActivity);
            }
            sessSetInterval();
            //window.addEventListener("mousemove", someActionInvoked, false);
            window.addEventListener("mousedown", someActionInvoked, false);
            window.addEventListener("keypress", someActionInvoked, false);
            window.addEventListener("DOMMouseScroll", someActionInvoked, false);
            window.addEventListener("mousewheel", someActionInvoked, false);
            window.addEventListener("touchmove", someActionInvoked, false);
            window.addEventListener("MSPointerMove", someActionInvoked, false);
        }
    };
    var sessSetInterval = function () {
        sessIntervalID = setInterval(sessInterval, sessPollInterval);
    };

    function sessClearInterval() {
        clearInterval(sessIntervalID);
    };
    var someActionInvoked = function (e) {
        sessLastActivity = new Date();
        localStorage.setItem('LastActivity', sessLastActivity);
        //console.log('LastActivity time updated' + sessLastActivity);

    };
    var sessLogOut = function () {
        if (isAuthenticatedQ()) {
            console.log('logging off authenticated user');
            window.location.href = "/LogoutRedirect?inactive=true";
        } else {
            console.log('user not authenticated. Redirect not required.');
        }
    };
    function sessInterval() {
        var now = new Date();
        var localStorageStoredDate;
        if (localStorage) {
            localStorageStoredDate = localStorage.getItem('LastActivity');
            if (localStorageStoredDate) {
                sessLastActivity = new Date(localStorageStoredDate);
                //console.log('local storage sessLastActivity: ' + sessLastActivity);
            }
        }
        var diff = now - sessLastActivity;
        var diffMins = (diff / 1000 / 60);
        //console.log('diffMins: ' + diffMins);
        //console.log('sessExpiryMins: ' + sessExpiryMins);
        if (diffMins > sessExpiryMins) {
            console.log('sessLastActivity: ' + sessLastActivity);
            console.log('diffMins: ' + diffMins);
            console.log('sessExpiryMins: ' + sessExpiryMins);
            console.log('logging off');
            sessClearInterval();
            sessLogOut();
        }
    };
    sessionCheck();
}