import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
    <div>
        <NavMenu />
        <div className="main-content">
            <Container>
                {this.props.children}
            </Container>
        </div>
        <footer className="footer px-0 mt-4 footer-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 mb-3">
                        <h3 className="text-white footer-teqsa-text">TEQSA</h3>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <h4 className="text-white">Using this website</h4>
                        <ul className="teqsa-list-styled list-unstyled">
                            <li className="">
                                <a className="fs-6 text-decoration-none mb-2 fw-lighter footer-link" target=" _blank" href="https://www.teqsa.gov.au/about-us/reporting-and-accountability/privacy">Privacy</a>
                            </li>
                            <li className="">
                                <a className="fs-6 text-decoration-none mb-2 fw-lighter footer-link" target=" _blank" href="https://www.teqsa.gov.au//disclaimer">Disclaimer</a>
                            </li>
                            <li className="">
                                <a className="fs-6 text-decoration-none mb-2 fw-lighter footer-link" target=" _blank" href="https://www.teqsa.gov.au/">TEQSA website</a>
                            </li>
                            <li className="">
                                <a className="fs-6 text-decoration-none mb-2 fw-lighter footer-link" target=" _blank" href="https://www.teqsa.gov.au/about-us/contact-us">Contact us</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </div>
    );
  }
}
