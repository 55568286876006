import React, { Component } from "react";
import PropTypes from "prop-types";

class AccordionSection extends Component {
    static propTypes = {
        children: PropTypes.instanceOf(Object).isRequired,
        isOpen: PropTypes.bool.isRequired,
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired
    };

    onClick = () => {
        this.props.onClick(this.props.label);
    };

    render() {
        const { onClick, props: { isOpen, label } } = this;

        return (
            <div
                style={{
                    background: isOpen ? "rgba(0, 0, 0, 0.03)" : "rgba(0, 0, 0, 0.03)",
                    border: "1px solid rgba(0, 0, 0, 0.125)"
                }}
            >
                <div onClick={onClick} style={{ padding: "20px 20px 20px 20px", cursor: "pointer" }}>
                    {label}
                    <div style={{ float: "right" }}>
                        {!isOpen && <span>&#9650;</span>}
                        {isOpen && <span>&#9660;</span>}
                    </div>
                </div>
                {isOpen && (
                    <div
                        style={{
                            background: "white",
                            borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
                            padding: "20px 20px 20px 20px"
                        }}
                    >
                        {this.props.children}
                       
                    </div>
                )}
            </div>
        );
    }
}

export default AccordionSection;
