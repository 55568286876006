import React, { Component } from 'react';
import DOMPurify from 'dompurify';
import authService from './api-authorization/AuthorizeService'

export class Providers extends Component {
    static displayName = Providers.name;

    constructor(props) {
        super(props);
        this.state = { providers: [], loading: true, query: '', pageContent:'' };
    }

    componentDidMount() {
        this.populateProvidersData();
        this.getPageContent();
    }
    static renderPageContent(pageContent) {
        return (
            <div>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(pageContent) }} />
            </div>
        );
    }
    async getPageContent() {
        const response = await fetch('/api/pagecontent/GetProvidersPageContent');
        const data = await response.text();
        this.setState({ pageContent: data, loading: false });
    }

    handleSearch = (event) => {
        this.setState({ query: event.target.value })
        console.log(this.state.query);
    };

    static renderProvidersTable(providers, query) {
        return (
            <div className="row">
                <div className="col-md-12">
                    <table className='table table-striped' aria-labelledby="tabelLabel">
                        <thead>
                            <tr>
                                <th>Provider Code</th>
                                <th>Provider Name</th>
                                <th>Trading Name(s)</th>
                                <th>Head Office Location</th>
                                <th>Campus Location(s)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {providers
                                .filter(provider => {
                                    if (query === '') {
                                        return provider;
                                    } else if (Object.keys(provider)
                                        .filter(key => (provider[key]||"").toString().toLowerCase().includes(query.toString().toLowerCase())).length > 0) {
                                        return provider;
                                    }
                                })
                                .map(provider =>
                                    <tr key={provider.spListItemId}>
                                        <td>{provider.higherEducationProviderCode}</td>
                                        <td>{provider.legalEntityName}</td>
                                        <td>{provider.tradingNames}</td>
                                        <td>{provider.headOfficeLocation}</td>
                                        <td>{provider.campusLocations}</td>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                    {(providers && providers.length === 0) ? "No providers are available." : ""}
                </div >
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : Providers.renderProvidersTable(this.state.providers, this.state.query);
        let pageContentToRender = this.state.loading
            ? <p><em>Loading...</em></p>
            : Providers.renderPageContent(this.state.pageContent);
        return (
            <div>
                {pageContentToRender }
                <div className="form-outline">
                    <input type="text" id="searchQuery" className="form-control" placeholder="Search" aria-label="Search"
                        onChange={this.handleSearch} value={this.state.query}/>
                </div>
                {contents}
            </div>
        );
    }

    async populateProvidersData() {
        const response = await fetch('api/providers');
        const data = await response.json();
        this.setState({ providers: data, loading: false });
    }

    //async populateProvidersDataWithAuth() {
    //    const token = await authService.getAccessToken();
    //    const response = await fetch('api/providers', {
    //        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    //    });
    //    const data = await response.json();
    //    this.setState({ providers: data, loading: false });
    //}


}
export default Providers;
