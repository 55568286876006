import React, { Component } from 'react';
import DOMPurify from 'dompurify';

import Accordion from "./Accordion";
export class Faqs extends Component {
    static displayName = Faqs.name;

    constructor(props) {
        super(props);
        this.state = { faqs: [], faqsqa: [], loading: true, query:'' };
    }

    componentDidMount() {
        this.populateFaqsData();
    }

    static renderPageContent(faqs, faqsqa) {
        return (
            <div>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faqs) }} />                

                <Accordion allowMultipleOpen>
                    {faqsqa
                        .map(faq =>
                            <div label={faq.question} key={faq.question}>
                                <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                            </div>)}
                </Accordion>
                <div className="row">
                    <div className="col-md-12 mt-2 gap-2 d-flex">
                        <a href="/Identity/VerifyIdentity/GreenId" className="btn btn-primary">Verify your Identity</a>
                        <a href="/Identity/VerifyIdentity/ManualIdVerification" className="btn btn-primary">Request Manual ID Verification</a>
                    </div>
                </div>

            </div>
        );
    }

    render() {
        let faqsContentToRender = this.state.loading
            ? <p><em>Loading...</em></p>
            : Faqs.renderPageContent(this.state.faqs, this.state.faqsqa);
        return (
            <div>
                {faqsContentToRender}
            </div>
        );
    }

    async populateFaqsData() {
        const response = await fetch('api/faqscontent');
        const responseqa = await fetch('api/faqsqa');
        const data = await response.text();
        const dataqa = await responseqa.json();
        this.setState({ faqs: data, faqsqa: dataqa, loading: false });
    }
}
export default Faqs;
