import React, { Component, Fragment } from 'react';
import { NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import * as InactiveSessionCheck from './LogoutInactiveUser';

export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
        setTimeout(InactiveSessionCheck.checkInactiveUser, 2000); //Call with 2 sec delay to run js after html elements are loaded
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

  render() {
    const { isAuthenticated, userName } = this.state;
    if (!isAuthenticated) {
      const registerPath = `${ApplicationPaths.Register}`;
      const loginPath = `${ApplicationPaths.Login}`;
      return this.anonymousView(registerPath, loginPath);
    } else {
      const profilePath = `${ApplicationPaths.Profile}`;
      const logoutPath = `${ApplicationPaths.LogOut}`;
      const logoutState = { local: true };
      return this.authenticatedView(userName, profilePath, logoutPath, logoutState);
    }
  }

    authenticatedView(userName, profilePath, logoutPath, logoutState) {
        return (<Fragment>
            {/*<NavItem>*/}
            {/*      <NavLink tag={Link} className="text-white" to={profilePath}>Hello {userName}</NavLink>*/}
            {/*</NavItem>*/}
            <NavItem>
                <NavLink replace tag={Link} className="text-white" to="/">Home</NavLink>
            </NavItem>
            <NavItem>
                <NavLink replace tag={Link} className="text-white" to="/MyApplications">My Applications</NavLink>
            </NavItem>
            <NavItem>
                <NavLink replace tag={Link} className="text-white" to="/Providers">Providers</NavLink>
            </NavItem>
            
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="text-white">
                    My Account
                </DropdownToggle>
                <DropdownMenu end>
                    <DropdownItem>
                        <a className="nav-link" href='/Identity/Account/Manage/ChangePassword'>Change Password</a>
                        <div id="isUserLoggedIn" style={{ display: "none" }}>true</div>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                        {/*<a className="nav-link" href='/Identity/Account/LogOut'>Logout</a>*/}
                        <NavLink replace tag={Link} className="logout-link" id="logout-link" to={logoutPath} state={logoutState}>Logout</NavLink>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            {/*    <NavLink replace tag={Link} className="text-white" to={logoutPath} state={logoutState}>Logout</NavLink>*/}
            {/*</NavItem>*/}
        </Fragment>);
    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            <NavItem>
                <NavLink replace tag={Link} className="text-white" to="/">Home</NavLink>
            </NavItem>
            <NavItem>
                <NavLink replace tag={Link} className="text-white" to="/Providers">Providers</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-white" to={registerPath}>Register</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-white" to={loginPath}>Login</NavLink>
            </NavItem>
        </Fragment>);
    }
}
